import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { post } from '../../../../helpers/api_helper';
// import Loader from '../../Loader';

const ContinueButtonModal = ({ showContinueModal, setShowContinueModal, input, operator, selectedCircle, selectedBiller, bbpsIconUrl, category, price }) => {

    const [loader, setLoader] = useState(false);

    const handleConfirm = () => {
        setLoader(true)
        let data = input;
        data.cir = selectedCircle.id ? selectedCircle.id : selectedCircle.CircleID;
        data.category = category;
        data.amt = price ? price : input.amt
        data.adParams = {};


        console.log(data)
        console.log(selectedCircle)
        console.log(operator, selectedBiller, input, category);

        validateRecharge(data);
    };



    const validateRecharge = async (data) => {
        try {
            const response = await post('/recharge/validate', data);
            console.log(response);

            if (response.data.status === "RECHARGEVALIDATIONSUCCESS") {

                let url = response.data.paymentData.instrumentResponse.redirectInfo.url;
                window.location.href = url;

            }
            setLoader(false)

        } catch (err) {
            setLoader(false)
        }
    };

    const initatePayment = async (data) => {

    }

    return (

        <Modal show={showContinueModal} onHide={() => setShowContinueModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Order Summary</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* {loader ? <Loader /> : ''} */}
                {/* {JSON.stringify(input)} */}
                <div>

                    <div class="row">
                        <p class="col-sm text-muted mb-0 mb-sm-3">Mobile Number:</p>
                        <p class="col-sm text-sm-end fw-500">{input.cn}</p>
                    </div>
                    <div class="row">
                        <p class="col-sm text-muted mb-0 mb-sm-3">Operator/Circle:</p>
                        <p class="col-sm text-sm-end fw-500">{selectedCircle.CircleName || selectedCircle?.label}</p>
                    </div>
                    <div class="row">
                        <p class="col-sm text-muted mb-0 mb-sm-3">Txn Charges</p>
                        <p class="col-sm text-sm-end fw-500">Rs. 0 </p>
                    </div>
                    <div class="row">
                        <p class="col-sm text-muted mb-0 mb-sm-3">Platform Charges</p>
                        <p class="col-sm text-sm-end fw-500">Rs. 0 </p>
                    </div>
                    <div class="row">
                        <p class="col-sm text-muted mb-0 mb-sm-3"><p className='primary'>Amount To be Paid :</p></p>
                        <p class="col-sm text-sm-end fw-500">Rs. {price ? price : input.amt}</p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Image src={bbpsIconUrl} style={{ width: "20%" }} />
                <Button variant="secondary" onClick={() => setShowContinueModal(false)}>
                    Close
                </Button>
                <Button variant="success" onClick={handleConfirm}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ContinueButtonModal