import React, { useEffect, useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import ContinueButtonModal from './ContinueButtonModal/ContinueButtonModal';
import { Form, Input } from 'reactstrap';
import BillModal from './BillModal/BillModal';
import { post } from '../../../helpers/api_helper';


const Postpaid = ({ billerRoot, mobileType, bbpsIconUrl, data, category, selectedOption }) => {
  const [input, setInput] = useState({});
  const [operatorName, setOperatorName] = useState('');
  const [showContinueModal, setShowContinueModal] = useState(false);
  const [showBillModal, setShowBillModal] = useState(false);
  const [operator, setOperator] = useState();

  const [operatorId, setOperatorId] = useState('29');
  const [price, setPrice] = useState();
  const [selectedCircle, setSelectedCircle] = useState({});
  const [selectedBiller, setSelectedBiller] = useState(undefined);
  const [resp, setResp] = useState([]);
  const [loading, setLoding] = useState(false);

  const fetchBill = async () => {
    try {
        let data = {
            ...input,
            adParams: { ad1: "" },
            cir: selectedCircle.CircleID,
            category: category,
            uid: "testalpha1@gmail.com",
            pswd: "testalpha1@123",
        };
        console.log(data);
        const response = await post('/recharge/bill', data);
        console.log(response);

        if (response?.success === true) {
            setResp(response.data);
            setPrice(resp[0].billAmount);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

  useEffect(() => {
    let selectedOp = billerRoot[0].billers.find((data) => data.type === mobileType);
    setOperator(selectedOp);
    setOperatorName(billerRoot[0].name);
    setOperatorId(String(selectedOp.op));

    if (selectedOp) {
      let opId = selectedOp.op;
      if (opId) {
        setInput((prevInput) => ({
          ...prevInput,
          category: selectedOption?.name,
          op: opId,
        }));
      }
    }
  }, []);

  useEffect(() => {
    if (operator) {
      for (let i = 0; i < billerRoot.length; i++) {
        for (let j = 0; j < billerRoot[i].billers.length; j++) {
          if (billerRoot[i].billers[j].type === 'Postpaid') {
            if (billerRoot[i].billers[j].op === Number(operatorId)) {
              setOperator(billerRoot[i].billers[j]);
            }
          }
        }
      }

    }
  }, [operatorId, selectedCircle]);

  const handlecirChange = (circleID) => {
    if (circleID !== "-1") {
      const circleName = data.circles[circleID];
      const circleObject = {
        "CircleID": circleID,
        "CircleName": circleName
      };
      setSelectedCircle(circleObject);
    }
  };

  const handleOpChange = (op) => {
    setOperatorId(op);
    setInput((prevInput) => ({
      ...prevInput,
      op: Number(op),
    }));
  };

  const handleChange = (id, value) => {
    setInput((prevInput) => ({
      ...prevInput,
      [id]: value,
    }));
  };

  const handleViewBillButton = async (e) => {
    await fetchBill();
};

const handleContinueButton = (e) => {
    setShowContinueModal(true);
    console.log(input);
};

const handleFormSubmit = (e) => {
    e.preventDefault();
    operator && operator.isViewBill ? handleViewBillButton() : handleContinueButton();
};

  return (
    <Form className='row g-3' onSubmit={handleFormSubmit}>
      <div className="row g-2">

        {/* Fields map */}
        {operator && operator.fields && (
          operator.fields.map((field, index) => (
            field.id !== "amt" && (
              <div key={index} className='col-lg col-md-6'>
                <Input
                  size='lg'
                  type={field.isnumeric ? 'number' : 'text'}
                  required
                  maxLength={field.maxLen}
                  onChange={(e) => {
                    if (field.isnumeric) {
                      e.target.value = e.target.value.slice(0, field.maxLen);
                    }
                    handleChange(field.id, e.target.value);
                  }}
                  placeholder={`Enter ${field.name}`}
                />
              </div>
            )
          ))
        )}

        <div className="col-md-6 col-lg">
          <Input name="select" required type="select" aria-label="Default select example" onChange={(e) => handlecirChange(Number(e.target.value))}>
            <option value={-1}>Select a Circle</option>
            {
              operator?.circles && operator?.circles.map((circle, index) => {
                const circleName = data.circles[circle];
                return (
                  <option key={index} value={circle}>{circleName}</option>
                );
              })
            }
          </Input>
        </div>

        <div className="col-md-6 col-lg">
          <Input required name="select" type="select" aria-label="Default select example" onChange={(e) => handleOpChange(e.target.value)}>
            {billerRoot
              .filter((item) => item.billers.some((biller) => biller.type === 'Postpaid'))
              .map((item, index) => {
                const op = item?.billers?.find((biller) => biller.type === 'Postpaid');
                const filerdOperator = billerRoot.find((operator) =>
                  operator.billers.some((biller) => biller.op === op.op)
                );
                return (
                  <option key={index} value={op.op}>
                    {filerdOperator.name}
                  </option>
                );
              })}
          </Input>
        </div>

        {
          operator && operator.isAmountRequired && (
            <div className="col-md-6 col-lg">
              {
                operator && operator.fields && (
                  operator.fields.map((field, index) => (
                    field.id === "amt" && (
                      <div key={index} className="position-relative">
                        {
                          field.browsePlans?.showButton === true && (<a href="#" data-bs-target="#view-plans" data-bs-toggle="modal" className="view-plans-link">View Plans</a>)
                        }
                        <Input required className="form-control" id="amount" placeholder="Enter Amount" type="number" />
                      </div>
                    )
                  ))
                )
              }
            </div>
          )
        }

        {/* Continue Button */}
        {
          operator && operator.isViewBill ? (
            <Col xs={12} md={6} lg={2}>
              <Button size='lg' className='px-5 btn w-100 h-100' type='submit'>
                View Bill
              </Button>
            </Col>
          ) : (
            <Col xs={12} md={6} lg={2}>
              <Button size='lg' className='px-5 btn w-100 h-100' type='submit'>
                Continue
              </Button>
            </Col>
          )
        }

        {/*  View Bill Button Modal*/}
        <BillModal
          showBillModal={showBillModal}
          setShowBillModal={setShowBillModal}
          input={input}
          data={resp}
          category={selectedOption.categoryId}
          selectedCircle={selectedCircle}
          setPrice={setPrice}
          handleContinueButton={handleContinueButton}
          bbpsIconUrl={bbpsIconUrl}
        />
        {/*  Continue Button Modal*/}
        <ContinueButtonModal
          showContinueModal={showContinueModal}
          setShowContinueModal={setShowContinueModal}
          input={input}
          category={category}
          price={price}
          operator={operator}
          selectedCircle={selectedCircle}
          bbpsIconUrl={bbpsIconUrl}
        />
      </div>
    </Form>
  );
};

export default Postpaid;
