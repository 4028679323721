import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Card, CardBody, Table, Button } from 'reactstrap';
import { post } from '../../helpers/api_helper';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS
 

const AepsTransactionList = () => {
    const [list, setList] = useState([]);
    const [filters, setFilters] = useState({
        transactionType: "AEPS",
        aepsStatus: "SUCCESS",
    });
    const [pageNumber, setPageNumber] = useState(0);
    const [totalPages, setTotalPages] = useState(1);  // Total number of pages
    const pageSize = 10;

    const fetchTransactionList = async () => {
        const formData = {
            pageNumber,
            pageSize,
            transactionType: filters.transactionType,
            aepsStatus: filters.aepsStatus,
            userId: ""
        };
        
        try {
            const response = await post('aeps/transaction/list', formData);
            setList(response.data.content);
            setTotalPages(response.data.totalPages);  
        } catch (error) {
            console.error('Error fetching transaction list:', error);
        }
    };
    
    useEffect(() => {
        fetchTransactionList();
    }, [filters, pageNumber]);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: value
        }));
    };

    const goToPage = (page) => setPageNumber(page);

    const columnDefs =[
        {headerName: 'Transaction ID', field: 'transactionId'},
        {headerName: 'Bank IIN', field: 'bankiin'},
        {headerName: 'AEPS Ref Number', field: 'aepsRefNumber'},
        {headerName: 'Status', field: 'status'},
        {headerName: 'Remarks', field: 'remarks'},
        {headerName: 'AEPS Operator Code', field: 'aepsOperatorCode'},
        {headerName: 'Amount', field: 'amount'},
        {headerName: 'Transaction Date', field: 'transactionDate'},
        {headerName: 'AEPS Status', field: 'aepsStatus'},
        {headerName: 'Transaction Type', field: 'txnType'},
        {headerName: 'Bank Name', field: 'bankname'},
    ]

    return (
        <React.Fragment>
            <div className="page-content">
                <Card>
                    <CardBody>
                        <div>
                            <select 
                                name="transactionType" 
                                onChange={handleFilterChange} 
                                value={filters.transactionType}
                                style={{ marginRight: '10px', padding: '5px' }}
                            >
                                <option value="AEPS">AEPS</option>
                                <option value="AADHARPAY">AADHARPAY(7)</option>
                                <option value="AEPSBE">AEPSBE(8)</option>
                                <option value="AEPSMS">AEPSMS(9)</option>
                                <option value="CASHDEP">CASHDEP(10)</option>
                            </select>

                            <select 
                                name="aepsStatus" 
                                onChange={handleFilterChange} 
                                value={filters.aepsStatus}
                                style={{ marginRight: '10px', padding: '5px' }}
                            >
                                <option value="SUCCESS">Success</option>
                                <option value="FAILED">Failed</option>
                                <option value="PENDING">Pending</option>
                            </select>
                        </div>
                        <div
                            className="ag-theme-alpine"
                            style={{ height: "500px", width: "100%", marginTop: "20px" }}
                        >
                        <AgGridReact 
                        rowData={list} 
                        columnDefs={columnDefs} 
                        pagination={true}
                        paginationPageSize={10}
                        />
                        </div>  
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    );
};

export default AepsTransactionList;
