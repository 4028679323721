import React, { useEffect, useState } from "react";
import { post } from "../helpers/api_helper";
import { Card, CardBody } from "reactstrap";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const MoveToBank = () => {
  const [rowData, setRowData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(1);
  const [isCheckboxVisible, setIsCheckboxVisible] = useState(true); // State to control checkbox visibility
  const pageSize = 5;

  useEffect(() => {
    // Check authUser role from localStorage
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    if (authUser && (authUser.role === "RETAILER" || authUser.role === "DISTRIBUTER")) {
      setIsCheckboxVisible(false);
    }
  }, []);

  const fetchData = async (pageNumber) => {
    try {
      const pageData = { pageNumber: pageNumber - 1, pageSize };
      const response = await post("pgpayment/withdraw/money/request/list", pageData);

      if (response) {
        setRowData(response.data.content);
        setTotalItems(response.data.totalItems || 0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(page);
  }, [page]);

  const onSelectionChanged = (params) => {
    const selectedNodes = params.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    setSelectedRows(selectedData);
  };

  const columnDefs = [
    ...(isCheckboxVisible
      ? [
          {
            headerCheckboxSelection: true, // Show header checkbox
            checkboxSelection: true, // Show row checkboxes
            width: 50,
          },
        ]
      : []), // If checkboxes are not visible, exclude this column
    { headerName: "Id", field: "id" },
    { headerName: "User Id", field: "userId" },
    { headerName: "Payment Reference", field: "paymentReference" },
    { headerName: "Payment Date", field: "paymentDate" },
    { headerName: "Amount", field: "amount" },
    { headerName: "Created On", field: "createdOn" },
    { headerName: "Status", field: "status" },
    { headerName: "Approved Remark", field: "approvedRemark" },
  ];

  const onPaginationChanged = (params) => {
    const newPage = params.api.paginationGetCurrentPage() + 1;
    setPage(newPage);
  };

  return (
    <div className="page-content">
      <Card>
        <CardBody>
          <div
            className="ag-theme-alpine"
            style={{ height: "500px", width: "100%" }}
          >
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              rowSelection="multiple"
              pagination={true}
              paginationPageSize={pageSize}
              onPaginationChanged={onPaginationChanged}
              onSelectionChanged={onSelectionChanged}
            />
          </div>
         
        </CardBody>
      </Card>
    </div>
  );
};

export default MoveToBank;
