import React, { useState, useEffect } from 'react';
import { Input, FormGroup, Label, Button } from 'reactstrap';
import image from '../assets/images/fingure.jpg';
import { get, post } from '../helpers/api_helper';

const DailyAuth = ({ registrationModel }) => {
  const [fingure, setFingure] = useState("");
  const [formData, setFormData] = useState({
    data: "",
    aadharNumber: '',
    latitude: 0,
    longitude: 0,
  });

  // Update formData whenever fingure changes
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      data: JSON.stringify(fingure),  // Convert fingure data to string format
    }));
  }, [fingure]);

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Capture fingerprint data
  const handleFingurePrint = async () => {
    try {
      const response = await get("https://jsonplaceholder.typicode.com/todos/1");
      if (response) {
        setFingure(response);  // Set fingure data to JSON response directly
        console.log('Fingerprint Data:', response);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const endpoint = registrationModel ? "aeps/merchant/register" : "aeps/merchant/authenticate";
    
    try {
      const response = await post(endpoint, formData);
      console.log('Form Data Sent:', formData);
      console.log('API Response:', response);
    } catch (err) {
      console.error('Error submitting form:', err);
    }
  };

  return (
    <div
      style={{
        maxWidth: '400px',
        margin: 'auto',
        padding: '20px',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <h3>{registrationModel ? 'Retailer Registration' : 'Daily Authentication'}</h3>

      <img src={image} alt="Fingerprint Authentication" style={{ width: '100px', marginBottom: '20px' }} />

      <Button color="primary" onClick={handleFingurePrint}>
        Capture Fingerprint
      </Button>

      <FormGroup className="mt-3">
        <Label for="aadharNumber">Aadhar Number</Label>
        <Input
          type="text"
          name="aadharNumber"
          id="aadharNumber"
          placeholder="Enter Aadhar number"
          value={formData.aadharNumber}
          onChange={handleChange}
          required
        />
      </FormGroup>

      <FormGroup check className="mt-3">
        <Label check>
          <Input type="checkbox" /> I/Customer hereby accept and confirm all terms and conditions.
        </Label>
      </FormGroup>

      <FormGroup check className="mb-4">
        <Label check>
          <Input type="checkbox" /> I/Retailer hereby accept and confirm all terms and conditions.
        </Label>
      </FormGroup>

      <Button color="primary" className="mt-3" onClick={handleSubmit}>
        Submit
      </Button>
    </div>
  );
};

export default DailyAuth;
