import React, { useEffect, useState } from "react";
import { CardBody, Card } from "reactstrap";
import { post } from "../helpers/api_helper";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS
import "ag-grid-community/styles/ag-theme-alpine.css"; // Theme CSS

const WalletTransactionDetails = () => {
  const [walletType, setWalletType] = useState("AEPS");
  const [newdata, setNewData] = useState({
    transactionDate: "",
    transactionStatus: "",
    transactionReference: "",
  });
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState(null);

  const getAllDetails = async () => {
    try {
      const filters = { ...newdata, walletType }; // Combine walletType and filters
      const response = await post(`/pgpayment/wallet/${walletType}/transaction`, filters);

      if (response && response.data) {
        setTransactions(response.data.content);
        setError(null);
      } else {
        setError(`Error fetching data: ${response.status} ${response.statusText}`);
      }
    } catch (err) {
      setError(`Fetch error: ${err.message}`);
    }
  };

  useEffect(() => {
    getAllDetails();
  }, [walletType, newdata]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Define column definitions
  const columnDefs = [
    { headerName: "Transaction ID", field: "transactionId", sortable: true, filter: true },
    { headerName: "Reference", field: "transactionReference", sortable: true, filter: true },
    { headerName: "Type", field: "transactionType", sortable: true, filter: true },
    { headerName: "Status", field: "transactionStatus", sortable: true, filter: true },
    { headerName: "Debit", field: "transactionAmountDebit", sortable: true, filter: true },
    { headerName: "Credit", field: "transactionAmountCredit", sortable: true, filter: true },
    { headerName: "Remarks", field: "transactionRemarks", sortable: true, filter: true },
    { headerName: "Date", field: "transactionDate", sortable: true, filter: true },
  ];

  return (
    <div className="page-content">
      <Card>
        <CardBody>
        <h3>Wallet Transaction Details</h3>
          <div className="mt-4 " style={{ display: "flex",  gap: "10px" }}>
            <select onChange={(e) => setWalletType(e.target.value)} value={walletType}>
              <option value="AEPS">AEPS</option>
              <option value="DMT">DMT</option>
              <option value="RTGS">RTGS</option>
            </select>

            <input
              type="date"
              onChange={handleChange}
              name="transactionDate"
              value={newdata.transactionDate}
            />

            <select
              onChange={handleChange}
              name="transactionStatus"
              value={newdata.transactionStatus}
            >
              <option value="">Select Status</option>
              <option value="PENDING">PENDING</option>
              <option value="SUCCESS">SUCCESS</option>
              <option value="FAILED">FAILED</option>
            </select>

            <input
              type="text"
              onChange={handleChange}
              name="transactionReference"
              value={newdata.transactionReference}
              placeholder="Search by Reference"
            />
          </div>

          

          {error && <p style={{ color: "red" }}>{error}</p>}

          <div
            className="ag-theme-alpine"
            style={{ height: "500px", width: "100%", marginTop: "20px" }}
          >
            {transactions.length > 0 ? (
              <AgGridReact
                rowData={transactions} // Data to display
                columnDefs={columnDefs} // Column definitions
                pagination={true} // Enable pagination
                paginationPageSize={10} // Rows per page
              />
            ) : (
              <p>No transactions found.</p>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default WalletTransactionDetails;
