import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import ContinueButtonModal from './ContinueButtonModal/ContinueButtonModal';
import ViewPlansModal from './ViewPlansModal/ViewPlansModal';
import axios from 'axios';
import { post } from '../../../helpers/api_helper';

let allCircles = [
    {
        "CircleID": 1,
        "CircleName": "Andhra Pradesh"
    },
    {
        "CircleID": 2,
        "CircleName": "Assam"
    },
    {
        "CircleID": 3,
        "CircleName": "Bihar & Jharkhand"
    },
    {
        "CircleID": 4,
        "CircleName": "Chennai"
    },
    {
        "CircleID": 5,
        "CircleName": "Delhi & NCR"
    },
    {
        "CircleID": 6,
        "CircleName": "Gujarat"
    },
    {
        "CircleID": 7,
        "CircleName": "Haryana"
    },
    {
        "CircleID": 8,
        "CircleName": "Himachal Pradesh"
    },
    {
        "CircleID": 9,
        "CircleName": "Jammu & Kashmir"
    },
    {
        "CircleID": 10,
        "CircleName": "Karnataka"
    },
    {
        "CircleID": 11,
        "CircleName": "Kerala"
    },
    {
        "CircleID": 12,
        "CircleName": "Kolkata"
    },
    {
        "CircleID": 13,
        "CircleName": "Maharashtra & Goa (except Mumbai)"
    },
    {
        "CircleID": 14,
        "CircleName": "MP & Chattisgarh"
    },
    {
        "CircleID": 15,
        "CircleName": "Mumbai"
    },
    {
        "CircleID": 16,
        "CircleName": "North East"
    },
    {
        "CircleID": 17,
        "CircleName": "Orissa"
    },
    {
        "CircleID": 18,
        "CircleName": "Punjab"
    },
    {
        "CircleID": 19,
        "CircleName": "Rajasthan"
    },
    {
        "CircleID": 20,
        "CircleName": "Tamilnadu"
    },
    {
        "CircleID": 21,
        "CircleName": "UP(EAST)"
    },
    {
        "CircleID": 22,
        "CircleName": "UP(WEST) & Uttarakhand"
    },
    {
        "CircleID": 23,
        "CircleName": "West Bengal"
    },
    {
        "CircleID": 51,
        "CircleName": "All India (except Delhi/Mumbai)"
    }
];

const Prepaid = ({ billerRoot, mobileType, bbpsIconUrl, data, category }) => {
    const [input, setInput] = useState({});
    const [operatorName, setOperatorName] = useState(billerRoot[0].name);
    const [showContinueModal, setShowContinueModal] = useState(false);
    const [operator, setOperator] = useState({});
    const [showViewPlansModal, setShowViewPlansModal] = useState(false)
    const [browsePlans, setBrowsePlans] = useState([]);
    const [error, setError] = useState(null);

    const [operatorId, setOperatorId] = useState(undefined);
    const [selectedCircle, setSelectedCircle] = useState({});
    const [selectedBiller, setSelectedBiller] = useState(undefined);
    const [circleOptions, setCircleOptions] = useState([]);

    useEffect(() => {
        let selectedOp = billerRoot.find(x => x.name === operatorName);
        setOperator(selectedOp)
        let billRoot = (selectedOp.billers).find(x => x.type === mobileType)
        setSelectedBiller(billRoot)
        let circleOptions = billRoot.circles.map(data => {
            let cirOb = allCircles.find(x => x.CircleID === data);
            return { label: cirOb.CircleName, value: cirOb.CircleName, id: cirOb.CircleID }
        });
        setCircleOptions(circleOptions);
    }, []);

    useEffect(() => {
        let selectedOp = billerRoot.find(x => x.name === operatorName);
        setOperator(selectedOp)
        let billRoot = (selectedOp.billers).find(x => x.type === mobileType)
        setSelectedBiller(billRoot)
    }, [operatorName]);


    const fetchData = async (opId, cirId) => {
        try {
            const data = {
                opId: opId + "",
                circleId: cirId + ""
            };

            const response = await post('/recharge/plans', data)
            console.log(response);
            setBrowsePlans(response.data.plans);
        } catch (err) {
            setError(err);
        }
    };




    useEffect(() => {
        console.log(selectedBiller, selectedCircle)
        if (selectedBiller != undefined && selectedCircle.id != undefined) {
            console.log(selectedBiller.op, selectedCircle.id)
            fetchData(selectedBiller.op, selectedCircle.id);

        }
    }, [selectedCircle]);


    useEffect(() => {
        setInput((prevInput) => ({
            ...prevInput,
            op: selectedBiller?.op,
        }));
    }, [operatorName, selectedBiller]);

    const handleChange = (id, value) => {
        const op = selectedBiller?.op;
        setInput((prevInput) => ({
            ...prevInput,
            op: selectedBiller?.op,
            [id]: value,
        }));
    };

    const handleOpChange = (data) => {
        setOperatorName(data.value)
        setOperatorId(data.op)
        console.log(data)
    }

    const handlecirChange = (circleData) => {
        console.log(circleData)
        setSelectedCircle(circleData)
    }


    const handleContinueButton = (e) => {
        e.preventDefault();
        console.log(input);

        const areAllIdsPresent = selectedBiller?.fields.every((field) => {
            return field && field.hasOwnProperty('id') && input.hasOwnProperty(field.id);
        });

        if (areAllIdsPresent) {
            setShowContinueModal(true);
        } else {
            alert('Please fill in all fields before continuing.');
        }
    };

    const operatorOptions = billerRoot
        .filter((item) => item.billers.some((biller) => biller.type === 'Prepaid'))
        .map((item) => {
            const op = item?.billers?.find(biller => biller.type === 'Prepaid').op;
            return { label: item.name, value: item.name, id: op }
        });

    return (
        <Form  >
            {/* Fields map */}
            {operator &&
                operator.billers &&
                operator.billers
                    .filter(biller => biller.type === "Prepaid")
                    .map((biller) => {
                        const fields = biller.fields.filter(field => field.id !== "amt");
                        return fields.map((field, index) => (
                            <div key={index} className='mb-3'>
                                <Form.Control
                                    size='lg'
                                    type={field.isnumeric ? 'number' : 'text'}
                                    value={input[field.id] || ''}
                                    required
                                    maxLength={field.maxLen}
                                    onChange={(e) => {
                                        if (field.isnumeric) {
                                            e.target.value = e.target.value.slice(0, field.maxLen);
                                        }
                                        handleChange(field.id, e.target.value);
                                    }}
                                    placeholder={`Enter ${field.name}`}
                                />
                            </div>
                        ));
                    })
            }
            {/* Operator Select */}

            <div className='mb-3'>
                <Select
                    options={operatorOptions}
                    value={operatorOptions.find(option => option.value === operatorName)}
                    isSearchable={true}
                    onChange={(selectedOption) => handleOpChange(selectedOption)}

                />
            </div>

            {selectedBiller != undefined ? <div className='mb-3'>
                <Select
                    label="Circles"
                    options={circleOptions}
                    onChange={(selectedOption) => handlecirChange(selectedOption)}
                    value={selectedCircle.CircleName}
                    isSearchable={true}
                />
            </div> : ""}

            {/* Additional Fields map */}
            {
                operator?.billers?.find(biller => biller.type === "Prepaid")?.isAmountRequired && (
                    operator.billers
                        .filter(biller => biller.type === "Prepaid")
                        .map((biller) => {
                            const fields = biller.fields.filter(field => field.id === "amt");
                            const browsePlans = operator.billers.find(biller => biller.type === "Prepaid").browsePlans;
                            return fields.map((field, index) => (
                                <div key={index} className='mb-3'>
                                    <div className='d-flex rounded focus' style={{ border: "2px solid #EFF0F3" }}>
                                        <Row>
                                            <Col xs="8">
                                                <Form.Control
                                                    className='m-0 border-0 removeFocus'
                                                    size='lg'
                                                    type='number'
                                                    value={input[field.id] || ''}
                                                    required
                                                    maxLength={field.maxLen}
                                                    onChange={(e) => {
                                                        if (field.isnumeric) {
                                                            e.target.value = e.target.value.slice(0, field.maxLen);
                                                        }
                                                        handleChange(field.id, e.target.value);
                                                    }}
                                                    placeholder={`Enter ${field.name.split('(') ? field.name.split('(')[0] : field.name}`}
                                                />
                                            </Col>
                                            <Col xs="4" className='d-flex justify-content-center align-items-center'>
                                                <Button className='m-0' variant='link' size='sm' onClick={() => setShowViewPlansModal(true)}>View Plans</Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            ));
                        })
                )
            }

            {/* Continue Button */}
            <Col xs={12} md={6} lg={2}>
                <Button size='lg' className='mb-3 ' type='submit' onClick={handleContinueButton} >Continue</Button>
            </Col>
            {/*  Continue Button Modal*/}
            <ContinueButtonModal
                showContinueModal={showContinueModal}
                setShowContinueModal={setShowContinueModal}
                input={input}
                operator={operator}
                selectedCircle={selectedCircle}
                selectedBiller={selectedBiller}
                bbpsIconUrl={bbpsIconUrl}
                category={category}
            />

            {showViewPlansModal ?
                <ViewPlansModal
                    showViewPlansModal={showViewPlansModal}
                    setShowViewPlansModal={setShowViewPlansModal}
                    setInput={setInput}
                    operatorName={operatorName}
                    selectedBiller={selectedBiller}
                    browsePlans={browsePlans}
                    data={data}
                /> : ""}

        </Form>
    )
}

export default Prepaid