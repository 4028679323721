import React, { useEffect, useState } from 'react';
import { get } from '../../helpers/api_helper';
import { Card, CardBody } from 'reactstrap';

const ProfilePage = () => {
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await get('retailer/profile');
        if (response && response.data) {
          setProfile(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchProfile();
  }, []);

  return (
    <div className="page-content">
      <Card>
        <CardBody>
            <h2 className="mb-4 text-center">Profile Page</h2>
          {profile ? (
              <div>
              <p><strong>ID:</strong> {profile.id}</p>
              <p><strong>Name:</strong> {profile.name}</p>
              <p><strong>DOB:</strong> {profile.dob}</p>
              <p><strong>Gender:</strong> {profile.gender}</p>
              <p><strong>Email:</strong> {profile.email}</p>
              <p><strong>Mobile:</strong> {profile.mobile}</p>
              <p><strong>Alternate Mobile:</strong> {profile.alternateMobile}</p>
              <p><strong>Shop Name:</strong> {profile.shopName}</p>
              <p><strong>Shop Type:</strong> {profile.shopType}</p>
              <p><strong>Shop Address:</strong> {profile.shopAddress}</p>
              <p><strong>Status:</strong> {profile.status}</p>
              <p><strong>User Type:</strong> {profile.userType}</p>
            </div>
          ) : (
            <p>User Not Found</p>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default ProfilePage;
