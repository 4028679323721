import React from 'react'
import { Button, Form, FormGroup, Label, Input } from "reactstrap";

const BalanceEnquiry = () => {
  return (
    <div> <div className="d-flex flex-start" style={{ width: "100%" }}>
    {/* Left side form */}
    <div className="form-container" style={{ width: "50%" }}>
      <Form
        style={{ width: "100%", margin: "auto" }}
        className="border p-4"
      >
        <FormGroup>
          <Label for="mobile">Mobile Number</Label>
          <Input
            type="number"
            id="mobile"
            placeholder="Enter your mobile number"
            className="mb-4"
          />
        </FormGroup>

        <FormGroup>
          <Label for="aadhar">Aadhar Number</Label>
          <Input
            type="number"
            id="aadhar"
            placeholder="Enter your Aadhar number"
            className="mb-4"
          />
        </FormGroup>

        <FormGroup>
          <Label for="bank">Bank Name</Label>
          <Input type="select" id="bank" className="mb-4">
            <option>-Select Bank-</option>
            <option>Bank 1</option>
            <option>Bank 2</option>
          </Input>
        </FormGroup>

        <FormGroup check className="mb-4">
          <Label check>
            <Input type="checkbox" /> I/Customer hereby accept and confirm
            all terms and conditions.
          </Label>
        </FormGroup>

        <FormGroup check className="mb-4">
          <Label check>
            <Input type="checkbox" /> I/Retailer hereby accept and confirm
            all terms and conditions.
          </Label>
        </FormGroup>

        <Button color="primary" className="btn-submit w-100">
          Submit
        </Button>
      </Form>
    </div>

    <div
      className="border p-3"
      style={{
        width: "50%",
        borderRadius: "8px",
        boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
      }}
    >
      <h6 className=" mb-3">Devices</h6>
      <div className="d-flex justify-content-around">
        <FormGroup className="d-flex flex-row align-items-center justify-content-center border border-1 border-black p-1 text-center">
          <Input type="radio" name="device" id="morpho" />
          <Label for="morpho" className="ms-2">
            Morpho
          </Label>
        </FormGroup>
        <FormGroup className="d-flex flex-row align-items-center justify-content-center border border-1 border-black p-1 text-center">
          <Input type="radio" name="device" id="mantra" />
          <Label for="mantra" className="ms-2 text-center">
            Mantra
          </Label>
        </FormGroup>
        <FormGroup className="d-flex flex-row align-items-center justify-content-center border border-1 border-black  p-1 text-center">
          <Input type="radio" name="device" id="other" />
          <Label for="other" className="ms-2">
            Other
          </Label>
        </FormGroup>
      </div>

          <Button color="primary" className="btn-submit w-100 mt-3">
          Capture Fingerprint
        </Button>
          
    </div>
  </div>
  </div>
  )
}

export default BalanceEnquiry