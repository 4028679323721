import React, { Component, StrictMode, useState, useMemo } from 'react';

import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPackage } from "../../store/package/actions";
import { Card, Spinner, Button, CardTitle, ButtonGroup, CardBody, CardSubtitle, CardText, Container, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, Row, Col, Table, Label, Alert, Form, FormGroup, Input } from "reactstrap";
import { withRouter, Link } from 'react-router-dom';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise'

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the grid
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { connect } from 'react-redux';
import { get, post } from "../../helpers/api_helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// availity-reactstrap-validation
import { AvForm, AvField, AvRadio } from 'availity-reactstrap-validation';
import AddDist from './AddDist';
import AepsMain from '../../components/AepsMain';

const CheckboxRenderer = (props) => {
  return (
    <input
      type="checkbox"
      checked={props.value}
      readOnly
    />
  );
};

const GridExample = (props) => {
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  LicenseManager.setLicenseKey("")
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const gridRef = useRef < AgGridReact > (null);
  // Row Data: The data to be displayed.
  useEffect(() => {
    console.log(props.data)
    setRowData(props.data)
  });


  const LinkCellRenderer = (props) => {
    const { value } = props;
    const url = `/subscribe/view/${value}`; // Construct your URL here

    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {value}
      </a>
    );
  };

  const WalletRenderer = (props) => {
    const { data } = props;
    const filteredData = data.filter(item => item.walletType === props.walletName);
    console.log(props, filteredData);

    return (
      <div>
        {filteredData.length > 0 && filteredData[0].balance}
      </div>
    );
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const handleView = (row) => {
    // Handle the view action
    alert(`Viewing details of ${row.name}`);
  };

  const handleAdd = (row) => {
    // Handle the add action
    alert(`Adding ${row.name}`);
  };

  const refreshUsers = () => {
    props.getAllUsers();
  }
  const onPaginationChanged = () => {
    if (gridApi) {
      console.log(gridApi)
      const currentPage = gridApi.paginationGetCurrentPage();
      const pageSize = gridApi.paginationGetPageSize();
      //getAllUsers(currentPage, pageSize);
    }
  };

  const handleCheckboxChange = (rowIndex, param) => {
    console.log(rowData, param)

    let rowDataA = [];
    param.api.forEachNode(node => rowDataA.push(node.data));
    console.log(rowDataA)
  };

  const [colDefs, setColDefs] = useState([
    { field: "id", headerName: 'Id', checkboxSelection: true },

    { field: "mobile", headerName: 'Phone', filter: "agTextColumnFilter" },
    { field: "usermname", headerName: 'User Name' },
    { field: "approved", headerName: 'Approved',cellRenderer: 'checkboxRenderer' },
    { field: "reqid", headerName: 'Request ID' },
    { field: "merchantcode", headerName: 'Merchant Code' },
    {
      field: "userType", headerName: 'Role',
      cellRenderer: (params) => (
        <div className='col-md-5'>
          {params.data.userType === 3 ? "RETAILER" : "DISTRIBUTOR"}
        </div>
      )

    },
    { field: "bank1", headerName: 'Bank 1',cellRenderer: 'checkboxRenderer' },
    { field: "bank2", headerName: 'Bank 2' ,cellRenderer: 'checkboxRenderer'},
    { field: "bank3", headerName: 'Bank 3' ,cellRenderer: 'checkboxRenderer'},

  

    { field: "onboarding_link", headerName: 'Onboarding Link' },
    {
      headerName: 'Actions',
      field: 'actions',
      cellRenderer: (params) => (
        <div className='col-md-5'>
          <Button outline onClick={() => handleView(params.data)} style={{ marginRight: '10px' }}>
            View
          </Button>
          <Button outline onClick={() => handleAdd(params.data)}>
            Add
          </Button>
        </div>
      ),
    },

  ]);


  const defaultColDef = {
    flex: 1,
    minWidth: 150,
    filter: "agTextColumnFilter",
    menuTabs: ["filterMenuTab"],
  }
  const getRowData = () => {
    let rowData = [];
    gridApi.forEachNode(node => rowData.push(node.data));
    alert(JSON.stringify(rowData))
  }
  function isFirstColumn(
    params
  ) {
    var displayedColumns = params.api.getAllDisplayedColumns();
    var thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }
  const gridOptions = {
    defaultColDef: {
      editable: true,
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      filter: true,
      flex: 1,
      minWidth: 100,
      checkboxSelection: isFirstColumn,

    },
    suppressRowClickSelection: true,
    groupSelectsChildren: true,
    // debug: true,
    rowGroupPanelShow: "always",
    pivotPanelShow: "always",
    columnDefs: colDefs,


  };
  return (
    <React.Fragment>
           


      <div
        className="ag-theme-alpine" // applying the grid theme
        style={{ height: 500 }} // the grid will fill the size of the parent container
      >



        <br></br>

        <AgGridReact
          onGridReady={onGridReady}
          rowData={rowData}
          gridOptions={gridOptions}
          defaultColDef={defaultColDef}
          columnDefs={colDefs}
          onPaginationChanged={onPaginationChanged}

        />
      </div>

    </React.Fragment>
    // wrapping container with theme & size

  )
}
const AepsOnbList = (props) => {

  const [state, setState] = useState({
    username: "admin",
    password: "test123",
    charge: 0.0,
    userType: "",
    data: [],
    errors: [],
    values: []
  });
  const [loader, setLoader] = useState(false)
  useEffect(() => {

    getAllUsers();
  }, loader);
  const getAllUsers = async () => {
    try {
      let data = { "pageNo": 0, "pageSize": 1000 }
      setLoader(true)
      const response = await post("admin/aeps/onboarding/list");
      console.log(response.data)

      setLoader(false);
      setState(prevState => ({ ...prevState, data: response.data.content }));
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <ToastContainer />
        <Row>
          <Col lg={12}>
            <Card>
              
              <CardBody>

                {loader ? <div className="loader-container text-center">
                  <Spinner style={{ width: '3rem', height: '3rem' }} /> {/* Loader Spinner */}
                </div> : ""}

                <Row>
                  {state.data.length > 0 ? <GridExample data={state.data} getAllUsers={getAllUsers} /> : <div>No Data</div>}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};


const mapStatetoProps = state => {
  return {};
}

export default withRouter(connect(mapStatetoProps, {})(AepsOnbList));

