import { Switch, Route, useRouteMatch } from 'react-router-dom';
import React, { Component, useEffect, useState } from "react";
 
import {
  Col,
  Container,

  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  CardTitle,
  CardText,
  Card
} from "reactstrap";
import { rechargeTypes, bankingServiceTypes } from '../menuConstants';

import classnames from "classnames";
import MiniWidgets from '../MiniWidgets';
import Breadcrumbs from '../../Common/Breadcrumb';
import { get } from '../../../helpers/api_helper';
import axios from 'axios';
const RechargeUi = () => {
  const { path } = useRouteMatch();
  const [aepsBalance,  setAepsBalance] = useState();
  const [Dmt, setDmt] = useState();
  const [activeTab, setActiveTab] = useState(1);
  const [breadCrumb, setBreadCrumb] = useState([{ title: "Nazox", link: "/" },
    { title: "Dashboard", link: "#" }])
    const [report, setReport] = useState([
      { icon: "ri-stack-line", title: "Number of Recharge", value: "1452", rate: "2.4%", desc: "From previous period" },
      { icon: "ri-store-2-line", title: "AEPS Balance", value: `₹ ${aepsBalance}`, rate: "2.4%", desc: "From previous period" },
      { icon: "ri-store-2-line", title: "Total Cash W", value:"₹ 38452" , rate: "2.4%", desc: "From previous period" },
      { icon: "ri-briefcase-4-line", title: "DMT Balance", value: `₹ ${Dmt}`, rate: "2.4%", desc: "From previous period" }]);
      
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get("retailer/profile");
        const aeps = response.data.wallet[0]?.balance || 0;
        const dmt = response.data.wallet[1]?.balance || 0;
        setAepsBalance(aeps);
        setDmt(dmt);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setReport((prevReport) => prevReport.map((item) => {
      if (item.title === "AEPS Balance") {
        return { ...item, value: `₹ ${aepsBalance}` };
      }
      if (item.title === "DMT Balance") {
        return { ...item, value: `₹ ${Dmt}` };
      }
      return item;
    }));
  }, [aepsBalance, Dmt]);
  

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Retailer Home" breadcrumbItems={breadCrumb} />
          <Row>
            <Col xl={12}>
              <Row>
                <MiniWidgets reports={report} />
              </Row>
            </Col>
          </Row>


          <Row>
            <Container fluid>
              <h5>Bill Payment Services</h5>
              <Card>
                <div className="d-lg-flex ">
                  <div className="chat-leftsidebar-nav">
                    <Nav pills justified tabs>
                      {
                        rechargeTypes.map((item, index) => (
                          <NavItem>
                            <NavLink href={`${item.path}`}
                            >
                              <i className={`${item.icon} font-size-20`}></i>
                              <span className="mt-2 d-none d-sm-block">{item.name}</span>
                            </NavLink>
                          </NavItem>

                        ))
                      }
                    </Nav>
                  </div>
                </div>
              </Card>
            </Container>
          </Row>

          <Row>
            <Col xl={2}>
              <Container fluid>
                <h5>Banking Services</h5>
                <Card>
                  <div className="d-lg-flex ">
                    <div className="chat-leftsidebar-nav">
                      <Nav pills justified tabs>
                        {
                          bankingServiceTypes.map((item, index) => (
                            <NavItem>
                              <NavLink href={`${item.path}`} >
                                <i className={`${item.icon} font-size-20`}></i>
                                <span className="mt-2 d-none d-sm-block">{item.name}</span>
                              </NavLink>
                            </NavItem>
                          ))
                        }
                      </Nav>
                    </div>
                  </div>
                </Card>
              </Container>
            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment>
  );
};

export default RechargeUi;