import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { get } from "../helpers/api_helper";

const UserProfileDetails = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchUserProfileDetails = async () => {
    try {
      const response = await get("/retailer/profile");
      setUserData(response.data);
    } catch (error) {
      setError("Failed to fetch user details.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserProfileDetails();
  }, []);

  return (
    <div className="page-content">
      <Card>
        <CardBody>
          <h3 >User Profile</h3>

          {loading && <p>Loading...</p>}
          {error && <p style={{ color: "red" }}>{error}</p>}

          {userData && (
            <div style={{ lineHeight: "1.8rem" }}>
              <p><strong>ID:</strong> {userData.id}</p>
              <p><strong>Name:</strong> {userData.name}</p>
              <p><strong>Mobile:</strong> {userData.mobile}</p>
              <p><strong>Alternate Mobile:</strong> {userData.alternateMobile}</p>
              <p><strong>Email:</strong> {userData.email}</p>
              <p><strong>Date of Birth:</strong> {userData.dob}</p>
              <p><strong>Gender:</strong> {userData.gender}</p>
              <p><strong>Shop Name:</strong> {userData.shopName}</p>
              <p><strong>Shop Type:</strong> {userData.shopType}</p>
              <p><strong>Shop Address:</strong> {userData.shopAddress}</p>
              <p><strong>City Pin Code:</strong> {userData.cityPinCode}</p>
              <p><strong>Status:</strong> {userData.status}</p>
              <p><strong>User Type:</strong> {userData.userType}</p>
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default UserProfileDetails;
