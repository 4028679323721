import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import CashWithdrawal from "./CashWithdrawal";
import BalanceEnquiry from "./BalanceEnquiry";
import MiniStatement from "./MiniStatement";
import { get } from "../helpers/api_helper";
import DailyAuth from "./DailyAuth";


const AepsComponent = ({onboardingModel, DailyAuthModel, registrationModel}) => {
    const [model, setModel] = useState("CashWithdrawal");
    const [newResponse, setNewResponse] = useState();


    const handleOnboarding = async() => {
        try{
            const response = await get("aeps/onboard");
            console.log(response.data);
            if(response.data?.onboardingLink?.length > 0){
                window.open(response.data?.onboardingLink, "_blank");
            }
        }catch(err){
            console.log(err);
        }
    }

    console.log('2', registrationModel);
    
  return (
    <div className="">
      { onboardingModel === false && <>
        <div className="p-3 bg-light rounded shadow-sm mb-4">
        <h3 className="">Aadhar Enabled Payment System</h3>
      </div>

      <div className="d-flex gap-3 mb-4">
        <button type="button" onClick={() => setModel("CashWithdrawal")} className={`btn ${model === "CashWithdrawal" ? "btn-primary" : "btn-outline-primary"}`}>
          Cash Withdrawal
        </button>
        <button type="button" onClickCapture={() => setModel("BalanceEnquiry")} className={`btn ${model === "BalanceEnquiry" ? "btn-primary" : "btn-outline-primary"}`}>
          Balance Enquiry
        </button>
        <button type="button" onClick={() => setModel("MiniStatement")} className={`btn ${model === "MiniStatement" ? "btn-primary" : "btn-outline-primary"}`}>
          Mini Statement
        </button>
      </div>
      </>
      }
 
 <div>
    {model === "CashWithdrawal" && onboardingModel === false && <CashWithdrawal />}
    {model === "BalanceEnquiry" && onboardingModel === false && <BalanceEnquiry />}
    {model === "MiniStatement" && onboardingModel === false && <MiniStatement />}
    {onboardingModel === true &&
    <div className="text-center">
        <h3>Your onboarding is still pending please start onboarding !</h3>
        <Button onClick={handleOnboarding} className="mt-4" >Start onboarding</Button>
    </div>
    }
 
 </div>
    </div>
  );
};

export default AepsComponent;