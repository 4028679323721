import React, { useEffect, useState } from "react";
import { get } from "../helpers/api_helper";
import AepsComponent from "./AepsComponent";
import DailyAuth from "./DailyAuth";

const AepsMain = () => {
  const [onboardingModel, setOnboardingModel] = useState(false);
  const [registrationModel, setRegistrationModel] = useState(false);
  const [dailyAuthModel, setDailyAuthModel] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get("aeps/precheck");
        const { onboarding, registration, dailyAuth } = response.data;
        console.log(response.data);

        if (!onboarding) {
          setOnboardingModel(true);
        } else if (onboarding && !registration) {
          setOnboardingModel(false);
          setRegistrationModel(true);
        } else if (onboarding && registration && !dailyAuth) {
          setRegistrationModel(false);
          setDailyAuthModel(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="page-content">
      {dailyAuthModel === false && registrationModel === false && (
        <AepsComponent
          onboardingModel={onboardingModel}
          registrationModel={registrationModel}
          DailyAuthModel={dailyAuthModel}
        />
      )}
      {registrationModel === true && <DailyAuth registrationModel={registrationModel} />}
      {dailyAuthModel === true && <DailyAuth />}
    </div>
  );
};

export default AepsMain;
