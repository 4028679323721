import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { post } from '../../../../helpers/api_helper';
import { Col, Image, Row } from 'react-bootstrap';

const BillModal = ({ showBillModal, setShowBillModal, input, setPrice, handleContinueButton, bbpsIconUrl, category, selectedCircle,data }) => {

    useEffect(() => {
        if (data.length > 0) {
            setPrice(data[0].billAmount);
        }
    }, [data])

    return (
        <Modal isOpen={showBillModal} >
            <ModalHeader toggle={() => setShowBillModal(false)}>Bill</ModalHeader>
            <ModalBody>
                <div>
                    <div style={{ backgroundColor: "#0C2F55" }} className='text-center fs-6 text-white fw-bold py-1 rounded-1'>Bill</div>
                    {
                        data && data.length > 0 ? (
                            <div className='px-3 pt-3'>
                                <Row>
                                    <Col className='text-black fs-6 fw-bold'>
                                        Mobile No:
                                    </Col>
                                    <Col className='text-black fs-6 fw-bold'>
                                        {data[0].cellNumber}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className='text-black fs-6 fw-bold'>
                                        Bill No:
                                    </Col>
                                    <Col className='text-black fs-6 fw-bold'>
                                        {data[0].billNumber}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className='text-black fs-6 fw-bold'>
                                        Bill Date:
                                    </Col>
                                    <Col className='text-black fs-6 fw-bold'>
                                        {data[0].billdate}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className='text-black fs-6 fw-bold'>
                                        Bill Due Date:
                                    </Col>
                                    <Col className='text-black fs-6 fw-bold'>
                                        {data[0].dueDate}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className='text-black fs-6 fw-bold'>
                                        Bill Net Amount:
                                    </Col>
                                    <Col className='text-black fs-6 fw-bold'>
                                        {data[0].billnetamount}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className='text-black fs-6 fw-bold'>
                                        Bill Status:
                                    </Col>
                                    <Col className='text-black fs-6 fw-bold'>
                                        {data[0].billStatus}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className='text-black fs-6 fw-bold'>
                                        Bill Status Message:
                                    </Col>
                                    <Col className='text-black fs-6 fw-bold'>
                                        {data[0].statusMessage}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className='text-black fs-6 fw-bold'>
                                        Bill Status Message:
                                    </Col>
                                    <Col className='text-black fs-6 fw-bold'>
                                        {data[0].billAmount}
                                    </Col>
                                </Row>
                            </div>

                        ) : (
                            <div className='text-center fs-6 fw-bold pt-4 pb-2'>
                                No Bill Available
                            </div>
                        )
                    }
                </div>
            </ModalBody>
            <ModalFooter>
                <Image src={bbpsIconUrl} style={{ width: "20%" }} />
                <Button color="secondary" onClick={() => setShowBillModal(false)}>
                    Cancel
                </Button>
                {
                    data.length > 0 && (
                        <Button color="success" onClick={(e) => { setShowBillModal(false); handleContinueButton(e) }}>
                            Continue
                        </Button>
                    )
                }

            </ModalFooter>
        </Modal>
    );
};

export default BillModal;
