import React, { useEffect, useState } from 'react';
import { Container, Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ViewPlansModal = ({ showViewPlansModal, setShowViewPlansModal, operatorName, selectedBiller, data, setInput, browsePlans }) => {
    const [browsePlansTypeId, setBrowsePlansTypeId] = useState(1);
    const [filterPlans, setFilterPlans] = useState([]);

    useEffect(() => {
        const filterBrowsePlans = browsePlans?.filter(browsePlan => browsePlan.operatorId === selectedBiller?.op);
        const plans = filterBrowsePlans.filter(filterBrowsePlan => filterBrowsePlan?.planTypeList.includes(browsePlansTypeId));
        setFilterPlans(plans);
    }, [browsePlansTypeId]);

    return (
        <Modal
            show={showViewPlansModal}
            onHide={() => setShowViewPlansModal(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {operatorName} Plans
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <div className='mb-3'>
                        {
                            data?.browsePlansMapping &&
                            Object.entries(data.browsePlansMapping).map(([key, value]) => {
                                return <Button
                                    key={key}
                                    onClick={() => setBrowsePlansTypeId(Number(key))}
                                    className='mx-2'
                                    style={{
                                        backgroundColor: Number(key) === browsePlansTypeId ? "#0C2F55" : "white",
                                        border: Number(key) === browsePlansTypeId ? "none" : "2px solid #0C2F55",
                                        color: Number(key) === browsePlansTypeId ? "white" : "#0C2F55",
                                        minWidth: "10rem"
                                    }}
                                >
                                    {value}
                                </Button>
                            })
                        }
                    </div>
                    <div>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Talktime</th>
                                    <th>Validity</th>
                                    <th>Description</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filterPlans?.map((browsePlan, index) => (
                                        <tr key={index}>
                                            <td>{browsePlan.talktime}</td>
                                            <td>{browsePlan.validity}</td>
                                            <td>{browsePlan.planDescription}</td>
                                            <td>
                                                <Button
                                                    style={{ backgroundColor: "#0C2F55", border: 'none' }}
                                                    onClick={() => {
                                                        setInput((prevInput) => ({
                                                            ...prevInput,
                                                            amt: browsePlan.amount,
                                                        }));
                                                        setShowViewPlansModal(false);
                                                    }}
                                                >
                                                    {browsePlan.amount}
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>

                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setShowViewPlansModal(false)}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ViewPlansModal