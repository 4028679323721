import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ResetPasswordPage from "../pages/Authentication/ResetPassword";
import RequestResetPass from "../pages/Authentication/RequestResetPass";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";
import PackageList from "../pages/PackageMgt/packageList";
// Dashboard
import Dashboard from "../pages/Dashboard/index";
import AddPackage from "../pages/PackageMgt/addPackage";
// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import UserList from "../pages/user/userList";
import PackageCommisionList from "../pages/PackageMgt/packagecommisionlist";
import SuperAdminDashboard from "../components/SuperAdminDashboard/index";
import ApiUserDashboard from "../components/ApiUserDashboard/index";
import DistributorDashboard from "../components/DistributorDashboard/index";
import RetailerDashboard from "../components/RetailerDashboard/route/index";
import RechargeUi from "../components/RetailerDashboard/route/index";
import { ROLES } from '../common/data/roles'
import AssignPackage from "../pages/PackageMgt/assignPackage";
import AssignedPackageList from "../pages/PackageMgt/AssignedPackageList";
import AddUserForm from "../pages/UserMgt/AddUser";
import SubscriptionList from "../pages/PackageMgt/SubscriptionList";
import AepsTransactionList from "../pages/PackageMgt/AepsTransactionList";
import SubList from "../pages/PackageMgt/SubList";
import ViewSubscription from "../pages/PackageMgt/ViewSubscription";
import Profile from "../pages/user/Profile";
import AepsOnbList from "../pages/user/AepsOnbList";
import TransactionList from "../pages/user/TransactionList";
import DmtTransactionList from "../pages/user/DmtTransactionList";
import RoleBasedRedirect from "./RoleBasedRedirect";
import BroadbandRechargeForm from "../components/RetailerDashboard/BroadBandRecharge";
import Mobile from "../components/retailer/mobile/Mobile";
import data from '../data.json';
import AepsMain from "../components/AepsMain";
import MoveToBank from "../components/MoveToBank";
import UserProfile from "../pages/UserProfile/UserProfile";
import WalletTransactionDetails from "../components/WalletTransactionDetails";
import UserProfileDetails from "../components/UserProfileDetails";
import RequestList from "../components/RequestList";

const authProtectedRoutes = [

	{ path: "/dashboard", component: SubList},
	{ path: "/subscription/list", component: SubList },
	{ path: "/assignpackage", component: AssignPackage },
	{ path: "/assignpackages", component: AssignedPackageList },
	{ path: "/addUser", component: AddUserForm },
	{ path: "/users", component: UserList },
	{ path: "/transactions", component: TransactionList },
	{ path: "/dmtlist", component: DmtTransactionList },
	{path: "/packagecommision/:packageid", component: PackageCommisionList },
	{ path: "/subscription", component: AddPackage },
	{ path: "/subscribe/view/:id", component: ViewSubscription },
	{ path: "/user/view/:id", component: Profile },
	{ path: "/aeps", component: AepsOnbList },
	{ path: '/superadmin', component: SuperAdminDashboard, role: ROLES[0] },
	{ path: '/apiuser', component: ApiUserDashboard, role: ROLES[1] },
	{ path: '/distributor', component: DistributorDashboard, role: ROLES[2] },
	{ path: '/retailer', component: RetailerDashboard, 	role: ROLES[4] },
	{ path: "/mobile", component: Mobile  },
	{ path: "/list", component: AepsMain  },
	{ path: "/AepsTransaction", component: AepsTransactionList  },
	{ path: "/MoveToBank", component: MoveToBank  },
	{ path: "/userProfile", component: UserProfile  },
	{ path: "/WalletTransDetails", component: WalletTransactionDetails  },
	{ path: "/UserProfileDetails", component: UserProfileDetails  },
	{ path: "/addmoney/list", component: RequestList  },
	// this route should be at the end of all other routes
	{ path: "/", exact: false, component: RoleBasedRedirect}
	
]

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: RequestResetPass },
	{ path: "/register", component: Login },
	{ path: "/lock-screen", component: AuthLockScreen },
	{ path: "/recharge", component: RechargeUi },
	

	// Authentication Inner
	{ path: "/auth-login", component: Login1 },
	{ path: "/auth-register", component: Register1 },
	{ path: "/auth-recoverpw/:token", component: ResetPasswordPage },
	

];

// Authenticated Routes with Roles
const authenticatedRoutesWithRoles = [
	
	
  ];

//flattened Routes

function flattenRoutes(routes){
	let flattenedRoutes = [];

	routes.forEach((route)=>{
		if(route.routes){
			const nestedRoutes = flattenRoutes(route.routes);
			flattenedRoutes = [...flattenedRoutes, ...nestedRoutes];
		}else{
			flattenedRoutes.push(route)
		}
	})
	return flattenedRoutes;
}
const authProtectedFlattenRoutes = flattenRoutes([...authenticatedRoutesWithRoles]);
export { authProtectedRoutes, publicRoutes, authProtectedFlattenRoutes };
