import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { post, put } from '../helpers/api_helper';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Button } from 'react-bootstrap';

const RequestList = () => {
  const [rowData, setRowData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);  
  const [isApproved, setIsApproved] = useState(null);  
  const [isModalOpen, setIsModalOpen] = useState(false);  
  const [remark, setRemark] = useState('');  

   
  useEffect(() => {
    const fetchRequestData = async () => {
      try {
        const data = { pageNumber: 0, pageSize: 10 };
        const response = await post('/pgpayment/add/money/request/list', data);
        if (response) {
          setRowData(response.data.content);  
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchRequestData();
  }, []);

   
  const openPopup = (row, action) => {
    setSelectedRow(row);
    setIsApproved(action);  
    setRemark(row.remark || '');  
    setIsModalOpen(true);
  };

 
  const handleSubmit = async () => {
    if (!selectedRow) {
      alert('No row selected!');
      return;
    }
    try {
      const endpoint = `/pgpayment/add/money/request/${selectedRow.id}/approve/${isApproved}?remark=${remark}`;
      const response = await put(endpoint);

      if (response && response.success) {
        console.log(`${isApproved ? 'Accepted' : 'Rejected'} successfully for request ID:`, selectedRow.id);

         
        setRowData((prevData) =>
          prevData.map((item) =>
            item.id === selectedRow.id
              ? { ...item, status: isApproved ? 'Accepted' : 'Rejected', remark } // Update remark
              : item
          )
        );
      } else {
        console.error('Error in updating request status:', response.message);
      }
    } catch (error) {
      console.error(
        `Error processing ${isApproved ? 'accept' : 'reject'} action for request ID:`,
        selectedRow.id,
        error
      );
    } finally {
      setIsModalOpen(false);  
    }
  };

  const authUser = JSON.parse(localStorage.getItem('authUser'));
  const userRole = authUser ? authUser.role : null;

 
  const columnDefs = [
    { headerName: 'ID', field: 'id', sortable: true, filter: true },
    { headerName: 'User ID', field: 'userId', sortable: true, filter: true },
    { headerName: 'Mode', field: 'mode', sortable: true, filter: true },
    { headerName: 'Payment Reference', field: 'paymentReference', sortable: true, filter: true },
    { headerName: 'Payment Date', field: 'paymentDate', sortable: true, filter: true },
    { headerName: 'Amount', field: 'amount', sortable: true, filter: true },
    { headerName: 'Remark', field: 'remark', sortable: true, filter: true },
    { headerName: 'Created On', field: 'createdOn', sortable: true, filter: true },
    { headerName: 'Status', field: 'status', sortable: true, filter: true },
    {
      headerName: 'Actions',
      field: 'actions',
      cellRenderer: (params) => {
        const { status } = params.data;
    
      
        const isActionAllowed = ['Success', 'Failed', 'Pending'].includes(status);
        if (userRole === 'RETAILER' || userRole === 'DISTRIBUTOR') {
            return null;  
          }
      
        return (
          <div>
            {isActionAllowed && (
              <>
                <Button
                  onClick={() => openPopup(params.data, true)} // Open popup for Accept
                  style={{
                    marginRight: '10px',
                    padding: '5px 10px',
                    backgroundColor: 'green',
                    color: 'white',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                >
                  Accept
                </Button>
    
                <Button
                  onClick={() => openPopup(params.data, false)} // Open popup for Reject
                  style={{
                    padding: '5px 10px',
                    backgroundColor: 'red',
                    color: 'white',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                >
                  Reject
                </Button>
              </>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="page-content">
      <Card>
        <CardBody>
          <h2 className="card-title">Request List</h2>
          <p className="card-title-desc">List of requests with actions</p>
          <div
            className="ag-theme-alpine"
            style={{ height: '500px', width: '100%', marginTop: '20px' }}
          >
            <AgGridReact
              rowData={rowData}  
              columnDefs={columnDefs}  
              pagination={true} 
              paginationPageSize={10}  
            />
          </div>
        </CardBody>
      </Card>

       
      {isModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            border: '1px solid #ccc',
            borderRadius: '5px',
            zIndex: 1000,
            boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
          }}
        >
          <h5>{isApproved ? 'Accept' : 'Reject'} Request</h5>
          <p>
            Are you sure you want to {isApproved ? 'accept' : 'reject'} the request for ID: {selectedRow.id}?
          </p>

           
          <div style={{ marginTop: '20px' }}>
            <label htmlFor="remark">Remark:</label>
            <textarea
              id="remark"
              value={remark}
              onChange={(e) => setRemark(e.target.value)} // Update remark on change
              rows="4"
              style={{
                width: '100%',
                padding: '8px',
                marginTop: '5px',
                borderRadius: '4px',
                border: '1px solid #ccc',
              }}
            />
          </div>

          <div style={{ marginTop: '20px', textAlign: 'right' }}>
            <Button
              onClick={handleSubmit}
              style={{
                padding: '5px 10px',
                backgroundColor: isApproved ? 'green' : 'red',
                color: 'white',
                border: 'none',
                cursor: 'pointer',
                marginRight: '10px',
              }}
            >
              Submit
            </Button>
            <Button
              onClick={() => setIsModalOpen(false)}
              style={{
                padding: '5px 10px',
                backgroundColor: '#ccc',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}

       
      {isModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 999,
          }}
          onClick={() => setIsModalOpen(false)}  
        ></div>
      )}
    </div>
  );
};

export default RequestList;
