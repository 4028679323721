import React, { useState,useEffect } from 'react';
import { Row, Col, Card, CardBody, ButtonGroup, Button } from 'reactstrap';
import ReactApexChart from 'react-apexcharts';

const RevenueAnalytics = () => {
const [data,setData]=useState([{ date: '2024-08-01', count: 120 },
    { date: '2024-08-02', count: 135 },
    { date: '2024-08-03', count: 150 },
    { date: '2024-08-04', count: 170 },
    { date: '2024-08-05', count: 180 },]);
    useEffect(() => {
        if (data) {
          const dates = data.map((item) => item.date);
          const userCounts = data.map((item) => item.count);
    
          setChartData((prevData) => ({
            ...prevData,
            series: [{ ...prevData.series[0], data: userCounts }],
            options: { ...prevData.options, xaxis: { ...prevData.options.xaxis, categories: dates } },
          }));
        }
      }, [data]);
      
  const [chartData, setChartData] = useState({
    series: [
      {
        name: 'Registered Users',
        data: [],
      },
    ],
    options: {
      chart: {
        type: 'line',
        height: 350,
      },
      xaxis: {
        categories: [],
        title: {
          text: 'Date',
        },
      },
      yaxis: {
        title: {
          text: 'Number of Users',
        },
      },
      title: {
        text: 'Daily Registered User Trend',
        align: 'center',
      },
    },
  });

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="float-end d-none d-md-inline-block">
            <ButtonGroup className="mb-2">
              <Button size="sm" color="light" type="button">Today</Button>
              <Button size="sm" color="light" active type="button">Weekly</Button>
              <Button size="sm" color="light" type="button">Monthly</Button>
            </ButtonGroup>
          </div>
          <h4 className="card-title mb-4">Revenue Analytics</h4>
          <div>
            <div id="line-column-chart" className="apex-charts" dir="ltr">
              <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="line"
                height={280}
              />
            </div>
          </div>
        </CardBody>

        <CardBody className="border-top text-center">
          <Row>
            <Col sm={4}>
              <div className="d-inline-flex">
                <h5 className="me-2">$12,253</h5>
                <div className="text-success">
                  <i className="mdi mdi-menu-up font-size-14"> </i>2.2 %
                </div>
              </div>
              <p className="text-muted text-truncate mb-0">This month</p>
            </Col>

            <Col sm={4}>
              <div className="mt-4 mt-sm-0">
                <p className="mb-2 text-muted text-truncate">
                  <i className="mdi mdi-circle text-primary font-size-10 me-1"></i> This Year :
                </p>
                <div className="d-inline-flex">
                  <h5 className="mb-0 me-2">$ 34,254</h5>
                  <div className="text-success">
                    <i className="mdi mdi-menu-up font-size-14"> </i>2.1 %
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={4}>
              <div className="mt-4 mt-sm-0">
                <p className="mb-2 text-muted text-truncate">
                  <i className="mdi mdi-circle text-success font-size-10 me-1"></i> Previous Year :
                </p>
                <div className="d-inline-flex">
                  <h5 className="mb-0">$ 32,695</h5>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default RevenueAnalytics;
