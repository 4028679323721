import React, { useEffect, useState } from 'react';
import { Container, Form, Image } from 'react-bootstrap';
import Prepaid from './Prepaid';
import Postpaid from './Postpaid';
import data from '../../../data.json';
const Mobile = ({ currentRoute, bbpsIconUrl, setCurrentRoute }) => {

    const [selectedOption, setSelectedOption] = useState(data.category[0])
    const [mobileType, setMobileType] = useState('Prepaid');
    const billerRoot = selectedOption?.billerRoot;

    console.log(JSON.parse(localStorage.getItem("authUser")));

   

    useEffect(() => {
        const route = currentRoute?.split("/")
        if (route !== undefined) {
            const op = data.category.find(item => item.categoryId === route[1]);
            setSelectedOption(op);
        }
    }, [currentRoute])

    return (
        <div class="page-content">

        
        <Container className='
          bg-white shadow-md rounded p-4 '>
            <p className="text-4 fw-bold mb-3">{selectedOption.name}</p>
            <div className="">
                <Form.Check
                    inline
                    label={'Prepaid'}
                    name="rechargeType"
                    type="radio"
                    value={'Prepaid'}
                    checked={mobileType === 'Prepaid'}
                    onChange={(e) => setMobileType(e.target.value)}
                />
                {
                    selectedOption && selectedOption.opTypes && selectedOption.opTypes.length > 1 && (
                        <Form.Check
                            inline
                            label={'Postpaid'}
                            name="rechargeType"
                            type="radio"
                            value={'Postpaid'}
                            checked={mobileType === 'Postpaid'}
                            onChange={(e) => setMobileType(e.target.value)}
                        />
                    )
                }
            </div>
            {
                mobileType === 'Prepaid' ?
                    <Prepaid
                        billerRoot={billerRoot}
                        mobileType={mobileType}
                        bbpsIconUrl={bbpsIconUrl}
                        data={data}
                        category={"mobile"}
                    />
                    : null
            }
            {
                mobileType === 'Postpaid' ?
                    <Postpaid
                        billerRoot={billerRoot}
                        mobileType={mobileType}
                        bbpsIconUrl={bbpsIconUrl}
                        data={data}
                        category={"mobile"}
                        selectedOption={selectedOption}
                    />
                    : null
            }
            {/* <div className='pt-2 pl-2'>
                <Image src={bbpsIconUrl} style={{ width: '10%' }} />
            </div> */}
        </Container>
        </div>
    );
};

export default Mobile;
